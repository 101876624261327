import styles from './PersonalInformationInput.module.scss';

import React, { useState } from 'react';
import PersonalInformationInputProps
    from '../../../../interface/CodeForm/PersonalInformationForm/PersonalInformationInput/PersonalInformationInputProps';

const PersonalInformationInput = (props: PersonalInformationInputProps) => {
    const { error, id, label, required, register, pattern, extendedError, type, maxLength } = props;

    const [active, setActive] = useState(false);
    const [value, setValue] = useState('');
    const [valid, setValid] = useState(true)

    const onBlur = () => {
        setActive(false);

        const isValid = !!value.match(pattern);

        setValid(isValid);
    };

    const onFocus = () => {
        setActive(true)
        setValid(true)
    };

    return (
        <div style={{position: 'relative'}}>
            <input
                type={type ? type === 'number' ? '\\d*' : type : 'text'}
                maxLength={maxLength}
                name={id}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={(e: any) => setValue(e.target.value)}
                ref={register({
                    required: required
                })}
                className={styles.personalInformationInput}
            />
            <label htmlFor={id} className={active || value !== '' ? styles.personalInformationLabelActive : styles.personalInformationLabel}>
                {label}
            </label>
            {error && <span className={extendedError ? styles.extendedRequired : styles.required}>Prosím, vyplňte toto pole.</span>}
            {!valid && !error && <span className={extendedError ? styles.extendedRequired : styles.required}>Nesprávny formát.</span>}
        </div>
    );
}

export default PersonalInformationInput;