import styles from './Footer.module.scss';

import React from 'react';

const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <img src="/svg/nmh.svg" alt="nmh" />
            <div className={styles.copyRight}>
                © 2021 News and Media Holding a.s. Autorské práva sú vyhradené a vykonáva ich vydavateľ. Akékoľvek rozmnožovanie časti alebo celku textov, fotografií, grafov akýmkoľvek spôsobom, v slovenskom, ale aj v inom jazyku bez písomného súhlasu vydavateľa je zakázané.
            </div>
        </div>
    );
}

export default Footer;