import styles from './Navbar.module.scss';

import React, { useState } from 'react';

import PlusManiaIcon from "./PlusManiaIcon/PlusManiaIcon";

const Navbar = () => {
    const [burgerMenuVisible, setBurgerMenuVisible] = useState(false);


    return (
        <div className={styles.navbarContainer}>
            <nav className={styles.navigation} >
                <a className={styles.logo} rel="noopener noreferrer" href="https://www1.pluska.sk/">
                    <img alt="logo" src="/svg/logo-plus1.svg" />
                </a>
                <div className={styles.links}>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/spravy">Správy</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/soubiznis">Šoubiznis</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/regiony">Regióny</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/krimi">Krimi</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/rady-a-tipy">Rady a tipy</a>
                    <a rel="noopener noreferrer" href="https://sport24.pluska.sk/">Šport24</a>
                    <a rel="noopener noreferrer" href="https://plus7dni.pluska.sk/">Plus 7 dní</a>
                </div>

                <PlusManiaIcon />

                <span className={styles.navbarItemContainer}>
                    <a
                        className={styles.articleLead}
                        rel="noopener noreferrer"
                        href="https://www1.pluska.sk/tip-redakcii"
                    >
                        Tip na článok
                    </a>
                </span>

                <div className={styles.burgerMenuContainer}>
                    <button onClick={() => setBurgerMenuVisible(!burgerMenuVisible)} className={styles.burgerMenu}>
                        <img src="/svg/burgerMenu.svg" alt="burgerMenu" />
                    </button>
                </div>
                <span className={styles.burgerMenuLinksContainer} style={{display: burgerMenuVisible ? 'inline-block' : 'none'}}>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/spravy">Správy</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/soubiznis">Šoubiznis</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/regiony">Regióny</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/krimi">Krimi</a>
                    <a rel="noopener noreferrer" href="https://www1.pluska.sk/r/rady-a-tipy">Rady a tipy</a>
                    <a rel="noopener noreferrer" href="https://sport24.pluska.sk/">Šport24</a>
                    <a rel="noopener noreferrer" href="https://plus7dni.pluska.sk/">Plus 7 dní</a>
                </span>
            </nav>
        </div>
    );
};

export default Navbar