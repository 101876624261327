import './custom.css';

import React, {useState} from 'react';
import Layout from './components/Layout/Layout';
import Navbar from './components/Navbar/Navbar';
import GiveAwayBox from "./components/GiveAwayBox/GiveAwayBox";
import CodeForm from "./components/CodeForm/CodeForm";
import Footer from "./components/Footer/Footer";
import axios from "axios";
import { scroller } from 'react-scroll';

import "./global.scss";

const App = () => {
    const [code, setCode] = useState('');
    const [codeValidated, setCodeValidated] = useState(false);
    const [wrongCodeWarningVisible, setWrongCodeWarningVisible] = useState(false);
    const [registrationCompleted, setRegistrationCompleted] = useState(false);
    const [waitingForResponse, setWaitingForResponse] = useState(false);

    const validateCode = () => {
        if (!waitingForResponse) {
            setWaitingForResponse(true);
            axios.post("/code/check", {code: code}).then(response => {
                setWaitingForResponse(false);
                if (response.status === 200) {
                    setCodeValidated(true);
                    // @ts-ignore
                    window.dataLayer.push({'event': 'plusmania-form-submit-code'})
                    scroller.scrollTo('codeFormContainer', {duration: 1000, smooth: true});
                }
            }).catch(error => {
                setWaitingForResponse(false);
                console.log(error)
                setWrongCodeWarningVisible(true);
            })
        }
    }


  return (
      <Layout>
          <Navbar />
          <GiveAwayBox
              setCodeValidated={setCodeValidated}
              registrationCompleted={registrationCompleted}
              setRegistrationCompleted={setRegistrationCompleted}
              setCode={setCode}
          />
          <CodeForm
              code={code}
              setCode={setCode}
              codeValidated={codeValidated}
              setCodeValidated={setCodeValidated}
              wrongCodeWarningVisible={wrongCodeWarningVisible}
              setWrongCodeWarningVisible={setWrongCodeWarningVisible}
              registrationCompleted={registrationCompleted}
              setRegistrationCompleted={setRegistrationCompleted}
              validateCode={validateCode}
              waitingForResponse={waitingForResponse}
              setWaitingForResponse={setWaitingForResponse}
          />
          <Footer/>
      </Layout>
  );
}

export default App
