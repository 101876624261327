import styles from './CodeInput.module.scss';

import React, { useLayoutEffect } from 'react';
import RICIBs from 'react-individual-character-input-boxes';
import CodeInputProps from "../../../interface/CodeForm/CodeInput/CodeInputProps";

const CodeInput = (props: CodeInputProps) => {
    const { setCode } = props;

    const handleOutput = (value: string) => {
        setCode(value);
    };

    useLayoutEffect(() => {
        document.getElementsByName('input0')[0].setAttribute('pattern', '\\d*');
        document.getElementsByName('input1')[0].setAttribute('pattern', '\\d*');
        document.getElementsByName('input2')[0].setAttribute('pattern', '\\d*');
        document.getElementsByName('input3')[0].setAttribute('pattern', '\\d*');
        document.getElementsByName('input4')[0].setAttribute('pattern', '\\d*');
        document.getElementsByName('input5')[0].setAttribute('pattern', '\\d*');

    });

    return (
        <div id="codeInput" className={styles.codeInput}>
            <RICIBs
                amount={6}
                handleOutputString={handleOutput}
                inputRegExp={/^[0-9]$/}
            />
        </div>
    );
};

export default CodeInput;