import styles from './CodeForm.module.scss';

import React, { useState } from 'react';
import CodeInput from "./CodeInput/CodeInput";
import PersonalInformationForm from "./PersonalInformationForm/PersonalInformationForm";
import axios from "axios";
import { scroller } from 'react-scroll'

import {CodeFormProps} from "../../interface/CodeForm/CodeFormProps";

const CodeForm = (props: CodeFormProps) => {
    const {
        code,
        setCode,
        codeValidated,
        setCodeValidated,
        wrongCodeWarningVisible,
        setWrongCodeWarningVisible,
        registrationCompleted,
        setRegistrationCompleted,
        validateCode,
        waitingForResponse,
        setWaitingForResponse
    } = props;

    const [errorOnSubmit, setErrorOnSubmit] = useState(false);
    const [conflictWarningVisible, setConflictWarningVisible] = useState(false);



    const onSubmit = (data: any) => {
        if(!waitingForResponse) {
            setWaitingForResponse(true);
            axios.post('/contactForm/submit', {...data, usedCode: code}).then((response) => {
                setWaitingForResponse(false);
                if (response.status === 200) {
                    setRegistrationCompleted(true);
                    // @ts-ignore
                    window.dataLayer.push({'event': 'plusmania-form-submit-contact'})
                    scroller.scrollTo('codeFormContainer', {duration: 1000, smooth: true});
                }
            }).catch(error => {
                setWaitingForResponse(false);
                console.log(error);

                if (error.request.status === 400) {
                    setWrongCodeWarningVisible(true)
                } else if (error.request.status === 409) {
                    setConflictWarningVisible(true);
                } else {
                    setErrorOnSubmit(true);
                }
            })
        }
    }

    const resetState = () => {
        setCode('');
        setCodeValidated(false);
        setRegistrationCompleted(false);
    }

    const competitionEnded = false;

    return (
        competitionEnded ?
            <div id="codeFormContainer" className={styles.codeFormContainer}>
                <div className={`${styles.enterCodeContainer} ${styles.finished}`}>
                    <div className={styles.enterCode}>
                        Ďakujeme za Váš záujem,<br /> súťaž bola ukončená.
                    </div>
                </div>
                <div className={styles.buttonContainer}>
                    <a href="https://www1.pluska.sk/spravy/z-domova/plus-jeden-den-rozdava-30-000-nedajte-ujst-sancu" rel="noopener noreferrer" target="_blank" className={styles.winnersLink}>
                        Výhercovia
                    </a>
                </div>
            </div>
            :
        <div id="codeFormContainer" className={styles.codeFormContainer}>
            {!registrationCompleted && (
            <div className={styles.enterCodeContainer}>
                <div className={styles.enterCode}>
                    Zadajte kód
                </div>
                <p className={styles.uniqueCodeText}>
                    Unikátny kód nájdete vždy <br/>
                    v aktuálnom denníku <br/>
                    Plus JEDEN DEŇ
                </p>
                <CodeInput setCode={setCode} />
                {!codeValidated && (
                    <div>
                        <button className={styles.continueButton} onClick={validateCode}>
                            Pokračovať na kontaktné údaje
                        </button>
                        {wrongCodeWarningVisible && (
                            <div className={styles.popupBackground}>
                                <div className={styles.wrongCodePopup}>
                                    <span>
                                        Nesprávny kód, zadajte prosím kód ešte raz.
                                    </span>
                                    <button onClick={() => setWrongCodeWarningVisible(false)}>
                                        OK
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            )}

            {codeValidated && !registrationCompleted && (
                <>
                <PersonalInformationForm onSubmit={onSubmit} waitingForResponse={waitingForResponse} />
                {errorOnSubmit && (
                    <div className={styles.popupBackground}>
                        <div className={styles.wrongCodePopup}>
                            <span>
                                Niečo sa nepodarilo, skúste prosím znova.
                            </span>
                            <button onClick={() => setErrorOnSubmit(false)}>
                                OK
                            </button>
                        </div>
                    </div>
                )}
                {conflictWarningVisible && (
                    <div className={styles.popupBackground}>
                        <div className={styles.wrongCodePopup}>
                        <span>
                            S uvedeným e-mailom ste sa už dnes do súťaže registrovali, môžete sa zapojiť iba jedenkrát denne.
                        </span>
                            <button onClick={() => setConflictWarningVisible(false)}>
                                OK
                            </button>
                        </div>
                    </div>
                )}
                </>
            )}

            {registrationCompleted && (
                <div className={styles.enterCodeContainer}>
                    <div className={styles.thanksForEntering}>
                        <div>
                            Ďakujeme, že ste sa zapojili do súťaže.
                        </div>
                        <div>

                        </div>
                    </div>
                    <div className={styles.thanksForEntering}>
                        <div>
                            V prípade výhry Vás budeme kontaktovať.
                        </div>
                    </div>
                    <button onClick={resetState} className={styles.enterNewCodeButton}>
                        Zadať nový kód
                    </button>
                    <img className={styles.zajo} src="/img/zajo.webp" alt="zajo" />
                </div>
            )}
        </div>
    );
}

export default CodeForm;