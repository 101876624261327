import styles from './PersonalInformationConsent.module.scss';

import React from 'react';
import PersonalInformationConsentProps
    from "../../../../interface/CodeForm/PersonalInformationForm/PersonalInformationConsent/PersonalInformationConsentProps";

const PersonalInformationConsent = (props: PersonalInformationConsentProps) => {
    const { register, text, id, required, error } = props;

    return (
        <div className={styles.consentContainer}>
            <input name={id} id={id} type="checkbox" ref={register({ required: required })} />
            <span className="checkmark" />
            <label htmlFor={id}>
                {text}
                {error && <span className={styles.required}>Prosím, vyplňte toto pole.</span>}
            </label>
        </div>
    );
}

export default PersonalInformationConsent;