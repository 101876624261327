import styles from './GiveAwayBox.module.scss';

import React from 'react';
import { scroller } from 'react-scroll'

import GiveAwayBoxProps from "../../interface/GiveAwayBox/GiveAwayBoxProps";

const GiveAwayBox = (props: GiveAwayBoxProps) => {
    const { setCodeValidated, registrationCompleted, setRegistrationCompleted, setCode } = props;

    const scrollToCodeFormContainer = () => {
        if (registrationCompleted) {
            setCode('');
            setCodeValidated(false);
            setRegistrationCompleted(false);
        }

        scroller.scrollTo('codeFormContainer', { duration: 1000, smooth: true, offset: -150 });
    };

    return (
        <div className={styles.giveAwayBox}>
            <div className={styles.textBlock}>
                <h1 className={styles.giveAwaySum}>
                    <span>JARNÁ PLUSMÁNIA</span>
                    <span className={styles.playText}>HRAJTE O <span>15 000 €</span></span>
                </h1>
                <h2 className={styles.giveAwayDaily}>
                    Súťaž prebieha denne od <span>13. 3.</span> do <span>1. 4. 2023</span>
                </h2>
                <button className={styles.sendCodeButton} onClick={scrollToCodeFormContainer}>
                    Zadať kód
                </button>
            </div>
            <div className={styles.zajo} />
        </div>
    );
}

export default GiveAwayBox;