import styles from './PersonalInformation.module.scss';

import React from 'react';
import { useForm } from "react-hook-form";

import PersonalInformationFormProps from "../../../interface/CodeForm/PersonalInformationForm/PersonalInformationFormProps";
import PersonalInformationInput from "./PersonalInformationInput/PersonalInformationInput";
import PersonalInformationConsent from "./PersonalInformationConsent/PersonalInformationConsent";

const PersonalInformationForm = (props: PersonalInformationFormProps) => {
    const { register, handleSubmit, formState } = useForm();
    const { errors } = formState;
    const { onSubmit, waitingForResponse } = props;

    return (
        <div>
            <div className={styles.personalInformation}>
                Vaše kontaktné údaje
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.formContainer}>

                    <div className={styles.leftColumn}>
                        <PersonalInformationInput error={errors.firstName} id={"firstName"} label={"Meno"} register={register} required maxLength={40} />
                    </div>

                    <div className={styles.rightColumn}>
                        <PersonalInformationInput error={errors.lastName} id={"lastName"} label={"Priezvisko"} register={register} required maxLength={40} />
                    </div>

                    <div className={styles.leftColumn}>
                        <PersonalInformationInput error={errors.street} id={"street"} label={"Ulica a popisné čislo"} register={register} required maxLength={40} />
                    </div>

                    <div className={styles.rightColumn}>
                        <div className={styles.cityAndZipContainer}>
                            <div className={styles.cityAndZipLeftColumn}>
                                <PersonalInformationInput error={errors.city} id={"city"} label={"Mesto"} register={register} required maxLength={40} />
                            </div>
                            <div className={styles.cityAndZipRightColumn}>
                                <PersonalInformationInput
                                    error={errors.zipCode}
                                    id={"zipCode"}
                                    label={"PSČ"}
                                    type="number"
                                    register={register}
                                    required
                                    extendedError
                                    pattern={/^[0-9]{5}$/}
                                    maxLength={5}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={styles.leftColumn}>
                        <PersonalInformationInput
                            error={errors.email}
                            id={"email"}
                            label={"E-mail"}
                            type="email"
                            register={register}
                            required
                            pattern={/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}
                            maxLength={40}
                        />
                    </div>

                    <div className={styles.rightColumn}>
                        <PersonalInformationInput
                            error={errors.phoneNumber}
                            id={"phoneNumber"}
                            label={"Telefónne číslo"}
                            register={register}
                            required
                            pattern={/^\+?[0-9]{3,}$/}
                            maxLength={40}
                        />
                    </div>
                </div>

                <p className={styles.rules}>
                    Úplné znenie pravidiel súťaže Jarná Plusmánia <a target="_blank" rel="noopener noreferrer" href="/pdf/2023_Statut_JARNA_Plusmania.pdf">nájdete tu</a>.
                </p>

                <PersonalInformationConsent
                    register={register}
                    text={<p>*Súhlasím s úplným znením pravidiel súťaže "Jarná Plusmánia" a beriem na vedomie, že moje osobné údaje uvedené v tomto formulári môžu byť spoločnosťou News and Media Holding, a.s. ďalej spracúvané na účely vybavenia mojej požiadavky, a to za podmienok a v súlade so <a target="_blank" rel="noopener noreferrer" href='https://www.newsandmedia.sk/gdpr/'>Zásadami ochrany osobných údajov</a>, s ktorými som sa dôkladne oboznámil(a).</p>}
                    id="consent"
                    required
                    error={errors.consent}
                />
                <PersonalInformationConsent
                    register={register}
                    text={<p>Súhlasím so zasielaním cielených marketingových aktivít a s príležitostným oslovením s ponukami tovarov a služieb spoločnosti News and Media Holding a.s. do odvolania súhlasu, max. po dobu 5 rokov.</p>}
                    id="marketingAgreement"
                />

                <div className={styles.submitContainer}>
                    <input type="submit" value={waitingForResponse ? 'Počkajte prosím...' : 'Odoslať'} />
                </div>
            </form>
        </div>
    );
}

export default PersonalInformationForm;