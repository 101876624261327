import styles from './PlusManiaIcon.module.scss';

import React from 'react';

const PlusManiaIcon = () => {

    return (
        <span className={styles.plusmaniaIcon}>
            <a className={styles.text} rel="noopener noreferrer" href="/">
                <img className={styles.easterPlusmania} alt="velkonocnaPlusmania" src="/img/velkonocna_plusmania.webp"/>
                <span >Hraj o 15 000 €</span>
            </a>
        </span>
    );
};

export default PlusManiaIcon;