import styles from './Layout.module.scss';

import React, {useState, useEffect} from 'react';

const Layout = (props: { children: any; }) => {
    const { children } = props;

    const [consentPanelOpened, setConsentPanelOpened] = useState(false);

    // control the consentPanelOpened state flag
    useEffect(() => {
        const checkInterval = setInterval(() => {
            const consentPanel = document.getElementById("onetrust-banner-sdk");
            if (consentPanel && consentPanel.style.display !== "none") {
                setConsentPanelOpened(true);
            }
            if (!consentPanel || consentPanel.style.display === "none") {
                setConsentPanelOpened(false);
            }
        }, 100);

        return () => {
            clearInterval(checkInterval);
        };
    }, []);

    return (
        <>
            {consentPanelOpened && (
                <style>
                    {`
                                .onetrust-pc-dark-filter.onetrust-pc-dark-filter.onetrust-pc-dark-filter {
                                  display: block !important;
                                  background: rgba(0,0,0,0.7);
                                  z-index: 100 !important;
                                }
                        `}
                </style>
            )}
            <div className={styles.preloadedImages} />
            <div className={styles.layout}>
                {children}
            </div>
        </>
    );
}

export default Layout;